import { createSlice } from "@reduxjs/toolkit";
import { fetchData } from './homeThunk';


const initialState = {
  loading: false,
  homeData: {},
  error:null
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchData.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.homeData = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.homeData = {};
        state.error = action.payload;
      });
     
  },
});

// export const {} = homeSlice.actions;

export default homeSlice.reducer;