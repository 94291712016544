import React from "react";
import { Link } from "react-router-dom";



import { fileURL } from "../../services/urls";
import menuicon from "../../assets/images/home/menu-icon.webp";

const EnterpriseSolution = ({ solutionList }) => {
	
	

  // Separate parent and child items
 
  let solutionResult = [...solutionList].sort(
    (a, b) => (a.order_no,10) - (b.order_no,10)
  );

  return (
    <>
      {/* <!-- enterprise-hospital-emrg-tec section start --> */}
      <section className="bg-white pt-lg-5 ">
			<div className="container_custom">
				<div className="row">
					<div className="col-md-8 m-auto" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="300">
						<div className="enter-hosp-emrg-tech-content">
							<ul>
                {solutionResult && solutionResult.map((item,index) => (
								<li key={index}>
									<Link to={item.slug}>
										<img src={`${fileURL}${item.image}`} alt={item.alt_text} />	
										<span>
                    {item.article_name}
										</span>
									</Link>
								</li>
                 ))}
                 <li>
									<Link className="more-menu-icon" to="">
										<img src={menuicon} />
										<br />
										<span>
											MORE
										</span>	
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
      {/* <!-- end enterprise-hospital-emrg-tec section --> */}
    </>
  );
};

export default EnterpriseSolution;
