import React, { useState } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import Slider from "react-slick";

function SearchComponent() {
  const searchSlider = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    cssEase: "ease-in",
    fade: false,
    draggable: true,
    autoplaySpeed: 2000,
    speed: 600,
    pauseOnDotsHover: false,
    centermode:false,
    responsive: [
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 5,
        },
      },

      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },

      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [activeLink, setActiveLink] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const handleLiClick = (value) => {
    setSearchTerm(value);
    setActiveLink(value);
  };

  const [error, setError] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // http://localhost:4000/api/search for local
      const response = await axios.post("https://redhealth.hashtechorange.com/api/search",{ keyword: searchTerm });
      // const response = await axios.post("http://localhost:4000/api/search",{ keyword: searchTerm });
      const data = response.data;
      if (data.length > 0) {
        const matchedItem = data[0]; // Assuming only one item is returned from the search
        //   window.location.href = matchedItem.url; // Redirect to the URL if data is found
        window.open(matchedItem.url, "_blank");
      } else {
        alert("No data found"); // Show alert if no data is found
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  return (
    <section className="mb-how-help">
      <div className="container_custom">
        <div className="row">
          <div className="col-md-11 m-auto" data-aos="fade-right" data-aos-easing="ease" data-aos-delay="200">
            <div className="srch_bx">
              <form
                className="is-search-form is-form-style is-form-style-3 is-form-id-1042"
                role="search"
                onSubmit={handleSubmit}
              >
                <input
                  type="search"
                  value={searchTerm}
                  name="keyword"
                  required
                  placeholder="HOW CAN I HELP YOU?"
                  className="srchbxinpt"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <input
                  type="submit"
                  className="search-submit btn srchbtn"
                  value=""
                />
              </form>
            </div>
          </div>
        </div>
        {/* <span >No Data Found</span> */}
        <div className="row">
          <div className="col-md-8 m-auto" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="200">
            <div className="symptom-speak-txt">
              <ul className="symptomXMb">
                <Slider {...searchSlider}>
                  <li>
                    <Link
                       className={activeLink === "I have symptom what should I do?" ? "active" : ""}
                      to=""
                      onClick={() =>
                        handleLiClick("I have symptom what should I do?")
                      }
                    >
                      I have symptom x what should I do?
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={activeLink === "I want to speak to doctor in 5 sec" ? "active" : ""}
                      to=""
                      onClick={() =>
                        handleLiClick("I want to speak to doctor in 5 sec")
                      }
                    >
                      I want to speak to doctor in 5 sec
                    </Link>
                  </li>
                  <li>
                    <Link
                     className={activeLink === "I need an ambulance" ? "active" : ""}
                      to=""
                      onClick={() => handleLiClick("I need an ambulance")}
                    >
                      I need an ambulance
                    </Link>
                  </li>
                  <li>
                    <Link
                     className={activeLink === "Suggest me the right hospital" ? "active" : ""}
                      to=""
                      onClick={() =>
                        handleLiClick("Suggest me the right hospital")
                      }
                    >
                      Suggest me the right hospital
                    </Link>
                  </li>
                  <li className="more-menu-icon">
                    <Link
                    className={activeLink === "Want a call back?" ? "active" : ""}
                      to=""
                      onClick={() => handleLiClick("Want a call back?")}
                    >
                      Want a call back?
                    </Link>
                  </li>
                </Slider>
              </ul>
            </div>
          </div>
        </div>
      </div>

     
    </section>
  );
}

export default SearchComponent;
