import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import blogArrow from "../../assets/images/home/blg-arrow.webp";

import { fileURL } from "../../services/urls";

const Blogs = ({ blogs }) => {

	const blogSlidder = {
		dots: true,
		infinite: true,
		slidesToShow: 1,	
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
		cssEase: 'ease-in',
		fade:false,
		draggable: true,
		autoplaySpeed: 3000,
		speed: 600,			
		pauseOnDotsHover: false,
    adaptiveHeight:true,
	};

  if (!blogs || !Array.isArray(blogs)) {
    return <div>Error: No blogs to display.</div>;
  }
  // Separate parent and child items

  const chunkArray = (blogs, chunkSize) => {
    const chunks = [];

    for (let i = 0; i < blogs.length; i += chunkSize) {
      chunks.push(blogs.slice(i, i + chunkSize));
    }
    return chunks;
  };
  const blogChunks = chunkArray(blogs, 4);
  return (
    <>
      <section className="bg-white desktopShow">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="headingFont">
                <h2>BLOGS</h2>
              </div>
            </div>
          </div>

          <div className="blogs_slide" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="100">
		  <Slider {...blogSlidder}>
            {blogChunks.map((chunk, chunkIndex) => (
              <div key={chunkIndex}>
                <div className="row blog-bt-mn">
                  <div className="col-md-6">
                    <div className="blog-lft-mn">
                      <img
                        className="img-fluid"
                        src={`${fileURL}${chunk[0].image}`}
						alt={chunk[0].alt_text}
                      />
                      <h2>
                        {chunk[0].article_name}
                      </h2>
                      <p>{chunk[0].short_description}</p>
                      <div className="blg-arrow-bt">
                        <Link
                          className="d-flex justify-content-end"
                          to={chunk[0].slug}
                        >
                          <img src={blogArrow} alt="blog arrow"/>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row m-lg-0 blog-rght-tp-mn">
                      <div className="col-md-7 p-0">
                        <img
                          className="img-fluid"
                          src={`${fileURL}${chunk[1].image}`}
						  alt={chunk[1].alt_text}
                        />
                      </div>
                      <div className="col-md-5">
                        <div className="blog-lft-mn p-0">
                          <h2 >
                            {chunk[1].article_name}
                          </h2>
                          <p>{chunk[1].short_description}</p>
                          <div className="blg-arrow-bt mt-0">
                            <Link
                              className="d-flex justify-content-end"
                              to={chunk[1].slug}
                            >
                              <img src={blogArrow} alt="blog arrow"/>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-lg-4">
                      {chunk[2] && (
                        <div className="col-md-6">
                          <div className="blog-lft-mn">
                            <img
                              className="img-fluid"
                              src={`${fileURL}${chunk[2].image}`}
							  alt={chunk[2].alt_text}
                            />
                            <h2>
                              {chunk[2].article_name}
                            </h2>
                            <p>{chunk[2].short_description}</p>
                            <div className="blg-arrow-bt mt-0">
                              <Link
                                className="d-flex justify-content-end"
                                to={chunk[2].slug}
                              >
                                <img src={blogArrow} alt="blog arrow"/>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-md-6">
                        {chunk[3] && (
                          <div className="blog-lft-mn">
                            <img
                              className="img-fluid"
                              src={`${fileURL}${chunk[3].image}`}
							  alt={chunk[3].alt_text}
                            />
                            <h2>
                              {chunk[3].article_name}
                            </h2>
                            <p>{chunk[3].short_description}</p>
                            <div className="blg-arrow-bt mt-0">
                              <Link
                                className="d-flex justify-content-end"
                                to={chunk[3].slug}
                              >
                                <img src={blogArrow} alt="blog arrow"/>
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
			</Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogs;
