// import React from "react";
import React, { useState, useRef } from "react";
import $ from "jquery"; // Import jQuery
import { fileURL } from "../../services/urls";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function getUniqueTCNamesWithCategory(data) {
  // Filter out unique tcname, category_id, and alt_text
  const uniqueTCNames = [];
  const seen = new Set();

  data.forEach((item) => {
    // Assuming alt_text is a property of the item object
    const key = `${item.tcname}_${item.testimonial_category_id}`;
    if (!seen.has(key)) {
      seen.add(key);
      uniqueTCNames.push({
        tcname: item.tcname,
        category_id: item.testimonial_category_id,
      });
    }
  });

  return uniqueTCNames;
}
function groupByCategoryId(data) {
  return data.reduce((acc, item) => {
    const categoryId = item.testimonial_category_id;

    if (!acc[categoryId]) {
      acc[categoryId] = [];
    }
    acc[categoryId].push(item);
    return acc;
  }, {});
}
const CategorySlider = ({ testimonials}) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const slidesToShow = Math.min(testimonials.length, 8);

  const slider1Ref = useRef();
  const slider2Ref = useRef();
  const sliderSettingsNew1 = {
    slidesToShow: 1,
    slidesToScroll: 1,
   
    dots: false,
    arrows: false,
    autoplay: true,
    lazyLoad: 'ondemand',
    autoplaySpeed: 3000,
   
    // afterChange: (currentSlide) => {
    //   slider2Ref.current.slickGoTo(currentSlide);
    // },
  };

  const carouselSettings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    asNavFor: slider1Ref.current,
    dots: true,
       arrows: false,
    centerMode: false,
    infinite: false,
    focusOnSelect: true,
    // beforeChange: (current, next) => {
    //   slider1Ref.current.slickGoTo(next);
    // },
  };

  let isMobile = window.innerWidth < 768;

    const updateIsMobile = () => {
      isMobile = window.innerWidth < 768; // Update isMobile when window size changes
      sliderSettingsNew1.dots = isMobile; // Toggle dots based on isMobile value
      carouselSettings.dots = isMobile; 
    };
    window.addEventListener('resize', updateIsMobile);
    // Call updateIsMobile initially to set the initial state
    updateIsMobile();

  return (
    <>
      <div className="mb-circle-bckgrnd">
        <div className="row">
          <div className="col-md-5">
            <div id="product__slider">
              <Slider
                {...carouselSettings}
                ref={slider2Ref}
                className="product__slider-thmb"
              >
                {testimonials &&
                  testimonials.map((iteminner, index) => (
                    <div
                      key={iteminner.id}
                      onClick={() => slider1Ref.current.slickGoTo(index)}
                    >
                      <div className="slide slide-crcle-fur">
                        <img className="img-fluid" src={`${fileURL}${iteminner.image}`} alt={iteminner.alt_text} />
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
          <div className="col-md-6 testim-rvw-icn-mn">
            <Slider
              {...sliderSettingsNew1}
              ref={slider1Ref}
              className="product__slider-main"
            >
              {testimonials &&
                testimonials.map((item5, index5) => (
                  <div key={item5.id}>
                    <div className="testim-rvw-icn">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="testim-rvw-photo">
                            {/* <img className="img-fluid" src={userImg} alt="" />  */}
                          <img className="img-fluid" src={`${fileURL}${item5.image}`} alt={item5.alt_text} />
                          </div>
                          <div className="testim-rvw-right-tp">
                            <p>{item5.description}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-6">
                          <div className="testim-rvw-right-bt">
                            <h2>{item5.name}</h2>
                            <h3>
                              {item5.designation}
                              {/* Senior Doctor */}
                            </h3>
                          </div>
                        </div>
                        <div className="col-md-8 col-6">
                          <div className="testim-str">
                            <div className="ratingControl">
                              <input
                                id="score100"
                                className="ratingControl__radio"
                                type="radio"
                                name="rating"
                                value="100"
                              />
                              <label
                                htmlFor="score100"
                                className={
                                  parseInt(item5.review) > 5
                                    ? "ratingControl__star active"
                                    : "ratingControl__star"
                                }
                                title="Five Stars"
                              ></label>
                              <input
                                id="score90"
                                className="ratingControl__radio"
                                type="radio"
                                name="rating"
                                value="90"
                              />
                              <label
                                htmlFor="score90"
                                className={
                                  parseInt(item5.review) > 4.5
                                    ? "ratingControl__star active"
                                    : "ratingControl__star"
                                }
                                title="Four & Half Stars"
                              ></label>
                              <input
                                id="score80"
                                className="ratingControl__radio"
                                type="radio"
                                name="rating"
                                value="80"
                              />
                              <label
                                htmlFor="score80"
                                className={
                                  parseInt(item5.review) > 4
                                    ? "ratingControl__star active"
                                    : "ratingControl__star"
                                }
                                title="Four Stars"
                              ></label>
                              <input
                                id="score70"
                                className="ratingControl__radio"
                                type="radio"
                                name="rating"
                                value="70"
                              />
                              <label
                                htmlFor="score70"
                                className={
                                  parseInt(item5.review) > 3.5
                                    ? "ratingControl__star active"
                                    : "ratingControl__star"
                                }
                                title="Three & Half Stars"
                              ></label>
                              <input
                                id="score60"
                                className="ratingControl__radio"
                                type="radio"
                                name="rating"
                                value="60"
                              />
                              <label
                                htmlFor="score60"
                                className={
                                  parseInt(item5.review) > 3
                                    ? "ratingControl__star active"
                                    : "ratingControl__star"
                                }
                                title="Three Stars"
                              ></label>
                              <input
                                id="score50"
                                className="ratingControl__radio"
                                type="radio"
                                name="rating"
                                value="50"
                              />
                              <label
                                htmlFor="score50"
                                className={
                                  parseInt(item5.review) > 2.5
                                    ? "ratingControl__star active"
                                    : "ratingControl__star"
                                }
                                title="Two & Half Stars"
                              ></label>
                              <input
                                id="score40"
                                className="ratingControl__radio"
                                type="radio"
                                name="rating"
                                value="40"
                              />
                              <label
                                htmlFor="score40"
                                className={
                                  parseInt(item5.review) > 2
                                    ? "ratingControl__star active"
                                    : "ratingControl__star"
                                }
                                title="Two Stars"
                              ></label>
                              <input
                                id="score30"
                                className="ratingControl__radio"
                                type="radio"
                                name="rating"
                                value="30"
                              />
                              <label
                                htmlFor="score30"
                                className={
                                  parseInt(item5.review) > 1.5
                                    ? "ratingControl__star active"
                                    : "ratingControl__star"
                                }
                                title="One & Half Star"
                              ></label>
                              <input
                                id="score20"
                                className="ratingControl__radio"
                                type="radio"
                                name="rating"
                                value="20"
                              />
                              <label
                                htmlFor="score20"
                                className={
                                  parseInt(item5.review) > 1
                                    ? "ratingControl__star active"
                                    : "ratingControl__star"
                                }
                                title="One Star"
                              ></label>
                              <input
                                id="score10"
                                className="ratingControl__radio"
                                type="radio"
                                name="rating"
                                value="10"
                              />
                              <label
                                htmlFor="score10"
                                className={
                                  parseInt(item5.review) > 0.5
                                    ? "ratingControl__star active"
                                    : "ratingControl__star"
                                }
                                title="Half Star"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};
const Testimonials = ({ testimonials, testimonialCategory, homeHeading }) => {
  const uniqueTCNames = getUniqueTCNamesWithCategory(testimonials);
  const groupedTestimonials = Object.entries(groupByCategoryId(testimonials));
  const [currentTab, setCurrentTab] = useState(uniqueTCNames[1].category_id);
  const handleTabClick = (tabId) => {
    
    setCurrentTab(tabId);
  };

  return (
    <>
      {}
      <section className="bg-grey-testim">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="headingFont">
                <h2>{homeHeading[6].value}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-center">
              <ul className="testimonial-tabs">
                {uniqueTCNames &&
                  uniqueTCNames.reverse().map((item, index) => (
                    <li
                      className={`tab-link ${
                        currentTab === item.category_id ? "current" : ""
                      }`}
                      onClick={() => handleTabClick(item.category_id)}
                      data-tab={item.category_id}
                      key={index}
                    >
                      {item.tcname}
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          {groupedTestimonials &&
            groupedTestimonials.map(([categoryId, testimonials]) => (
              <div
              key={categoryId}
                id={`tab-` + categoryId}
                className={`tab-content ${
                  currentTab.toString() === categoryId.toString()
                    ? "current"
                    : ""
                }`}
              >
                <CategorySlider key={categoryId} testimonials={testimonials} />
              </div>
            ))}
        </div>
      </section>
      {}
    </>
  );
};

export default Testimonials;