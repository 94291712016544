// import React from "react";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import arrowRight from '../../assets/images/home/arrw-rght.png';
import mobileImage from '../../assets/images/home/about-us-mobile-img.webp';
import parse from "html-react-parser";
import AOS from 'aos';
import 'aos/dist/aos.css';


import { fileURL } from "../../services/urls";
const CountUpAnimation = ({
    initialValue,
    targetValue,
}) => {
    const [count, setCount] = useState(initialValue);
    const duration = 4000; // 4 seconds
 
    useEffect(() => {
        let startValue = initialValue;
        const interval = Math.floor(
            duration / (targetValue - initialValue));
 
        const counter = setInterval(() => {
            startValue += 1;
            setCount(startValue);
            if (startValue >= targetValue) {
                clearInterval(counter);
            }
        }, interval);
 
        return () => {
            clearInterval(counter);
        };
    }, [targetValue, initialValue]);
 
    return (
		<>
		{count}
		</>
		
    );
};

const AboutUs = ({ homeAboutUs }) => {
  // Separate parent and child items
  return (
    <>
      <section className="bg-white">
			<div className="container_custom">
				<div className="row">
					<div className="col-md-12">
						<div className="gry-bg-clr" data-aos="fade-right" data-aos-easing="ease" data-aos-delay="300">
							{ homeAboutUs && homeAboutUs.map((item,index) => (
								<div className="row" key={index}>
								<div className="col-md-5">
									<div className="abut-img desktop-banner-image">
										<img className="img-fluid" src={`${fileURL}${item.image}`}  alt={item.alt_text}/>
									</div>
									
									{/* <img className="mobile_view img-fluid" src={`${fileURL}${item.image}`}  alt={item.alt_text}/> */}
								</div>
								<div className="col-md-7">
									<div className="about-us-content">
										<h2>
											{item.heading}
										</h2>
										<div className="abut-img mobile-banner-image">
											<img className="img-fluid" src={mobileImage}  alt={item.alt_text}/>
										</div>
										{/* <p> */}
											{parse(item.sub_heading)}
										{/* </p> */}
									</div>
									<div className="book-clr-btn book-clr-btn1 paading-top">
											<Link to="">
												<span>
													READ MORE
												</span>
												<img src={arrowRight} alt="arrow right"/>
											</Link>
										</div>
									<div className="row">
										<div className="col-md-12">
											<div className="ltsrcphfa">
												<ul>
													<li>
													<CountUpAnimation
                   
                    initialValue={0}
                    targetValue={item.lives_touched_no}
                   
                />
														{item.lives_touched_no_symbol}
														<br />
														<span>
														{item.lives_touched_text}
														</span>
													</li>
													<li>
													<CountUpAnimation
                   
				   initialValue={0}
				   targetValue={item.satisfaction_rate_no}
				  
			   />
														{item.satisfaction_rate_no_symbol}
														<br />
														<span>
														{item.satisfaction_rate_text}
														</span>
													</li>
													<li>
													<CountUpAnimation
                   
				   initialValue={0}
				   targetValue={item.cities_no}
				  
			   />
														{item.cities_no_symbol}
														<br />
														<span>
														{item.cities_text}
														</span>
													</li>
													<li>
													<CountUpAnimation
                   
				   initialValue={0}
				   targetValue={item.partnered_hospitals_no}
				  
			   />
														{item.partnered_hospitals_no_symbol}
														<br />
														<span>
														{item.partnered_hospitals_text}
														</span>
													</li>
													<li>
													<CountUpAnimation
                   
				   initialValue={0}
				   targetValue={item.fleet_of_ambulances_no}
				  
			   />
														{item.fleet_of_ambulances_no_symbol}
														<br />
														<span>
														{item.fleet_of_ambulances_text}
														</span>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}							
						</div>
					</div>
				</div>
			</div>
		</section>
		 
    </>
  );
};

export default AboutUs;