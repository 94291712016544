import React from "react";
import { Link } from "react-router-dom";
import arrowRight from "../../assets/images/home/arrw-rght.png";

import { fileURL } from "../../services/urls";
import Slider from "react-slick";


const AmbulanceService = ({ ambulanceServices, homeHeading }) => {
  // Separate parent and child items
  const sliderAmbulance = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    cssEase: 'ease-in',
    fade:false,
    draggable: true,
    autoplaySpeed: 2000,
    speed: 600,			
    pauseOnDotsHover: false,
  
	responsive: [
{
breakpoint: 1550,
settings: {
 slidesToShow: 3,
}
 },
		{
breakpoint: 800,
settings: {
 slidesToShow: 2,
}
 },
		{
breakpoint: 580,
settings: {
 slidesToShow: 1,
}
 }
 ]
	
  }
  return (
    <>
      {}
      <section className="bg-white mb-lg-5">
        <div className="container_custom">
          <div className="row">
            <div className="col-md-12">
              <div className="headingFont">
                <h2>{homeHeading[0].value}</h2>
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="100">
            <Slider {...sliderAmbulance}>
            {ambulanceServices &&
              ambulanceServices.map((item, index) => (
                <div key={index} className="col-md-4">
                  <div
                    className={
                      item.order_no == "2"
                        ? "air-ambilances-mn roadAmbulance"
                        : "air-ambilances-mn"
                    }
                  >
                    <div className="air-ambilances-tp text-center">
                      <h2>{item.heading}</h2>
                    </div>
                    <div className="air-ambilances-bt">
                      {item.order_no == "2" ? (
                        <>
						<div className="col-md-6">
                            <div className="air-amvu-rht">
                              <p>{item.description}</p>
                              <Link to="">Read More</Link>
                            </div>
                            <div className="book-clr-btn">
                              <Link to={item.book_link}>
                                <span>Book</span>
                                <img src={arrowRight} alt="arrow right" />
                              </Link>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="air-amvu-lft">
                              <img
                                className="img-fluid"
                                src={`${fileURL}${item.image}`}
                                alt={item.alt_text}
                              />
                            </div>
                          </div>
                          
                        </>
                      ) : (
                        <>
                          <div className="col-md-6">
                            <div className="air-amvu-lft">
                              <img
                                className="img-fluid"
                                src={`${fileURL}${item.image}`}
                                alt={item.alt_text}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="air-amvu-rht">
                              <p>{item.description}</p>
                              <Link to="">Read More</Link>
                            </div>
                            <div className="book-clr-btn">
                              <Link to={item.book_link}>
                                <span>Book</span>
                                <img src={arrowRight} alt="arrow right" />
                              </Link>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              </Slider>
          </div>
        </div>
      </section>
      {}
    </>
  );
};

export default AmbulanceService;
