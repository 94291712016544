import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../services/api';
import { HOME_URL } from '../../../hooks/url';

export const fetchData = createAsyncThunk(
  'home/fetchData',
  async (_, { rejectWithValue }) => {
    try {
      // Log the current page URL
      console.log('Request originated from:', window.location.href);

      const response = await api.get(HOME_URL);
      // Store the data in localStorage
      localStorage.setItem('homeData', JSON.stringify(response.data));
      return { ...response.data };
    } catch (e) {
      // If the API request fails, try to fetch the data from localStorage
      const dataFromLocalStorage = localStorage.getItem('homeData');
      if (dataFromLocalStorage) {
        return JSON.parse(dataFromLocalStorage);
      }
      return rejectWithValue('');
    }
  }
);