import React, { useEffect, useRef } from "react";
import { fetchData } from "../store/slices/home/homeThunk";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/header/Header";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Loader from "../components/common/Loader";
// import useScrollRestoration from "../hooks/useScrollRestoration"; // Import the custom hook
import home from "../store/slices/home/home";
import Footer from "../components/footer/footer";
import Auth from "../hooks/Auth";
import '../assets/css/common.css';
function RootLayout() {
  const dispatch = useDispatch();
  useEffect(() => {
    AOS.init();
    
  }, []);
  console.log("fetchdata",fetchData);
  const { homeData, loading } = useSelector((state) => state.home);
  const dataFetchedRef = useRef(false); // Ref to track if data has been fetched
  // Use the custom scroll restoration hook
  // useScrollRestoration();
  useEffect(() => {
    // Check if the data has already been fetched
    if (!dataFetchedRef.current) {
      // Check if the application is offline
      if (!navigator.onLine) {
        // Fetch the data from localStorage
        const dataFromLocalStorage = localStorage.getItem("homeData");
        if (dataFromLocalStorage) {
          dispatch(fetchData.fulfilled(JSON.parse(dataFromLocalStorage)));
        } else {
          // Handle the case where there's no data in localStorage
          console.log("No data available offline");
        }
      } else {
        // Fetch the data from the API
        dispatch(fetchData());
      }
      // Set the ref to true to indicate that the data has been fetched
      dataFetchedRef.current = true;
    }
  }, [dispatch]);

  // if (loading) {
  //   return <Loader />;
  // }
  // console.log(homeData)
  return (
    <>

      {/* {homeData && homeData.menu  && homeData.globalSetting && ( */}
        <>
        { homeData.menu && homeData.quickTop && homeData.footerAddress && (
          <Header menu={homeData.menu} quickTop={homeData.quickTop}  address={homeData.footerAddress} />
        )} 
         <main>
            <Outlet />
          </main>
        { homeData.footer && homeData.footerAddress && homeData.socialMedia && homeData.footerLogo &&homeData.footerBottom && homeData.FooterBottomMenu && (
          
          <Footer footer={homeData.footer}  address={homeData.footerAddress} socialMedia={homeData.socialMedia} footerLogo={homeData.footerLogo} footerBottom={homeData.footerBottom} FooterBottomMenu={homeData.FooterBottomMenu}/>
          )}
          
        </>
      {/* )} */}
    </>
  );
}

export default RootLayout;