// import React from "react";
// import React, { useState } from "react";
import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import AOS from 'aos';
import 'aos/dist/aos.css';

import { fileURL } from "../../services/urls";
import arrowRight from "../../assets/images/home/arrw-rght.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery";

function getUniqueTCNamesWithCategory(data) {
  // Filter out unique tcname, category_id, and alt_text
  const uniqueEPNames = [];
  const seen = new Set();

  data.forEach((item) => {
    // Assuming alt_text is a property of the item object
    const key = `${item.category_name}_${item.category}`;
    if (!seen.has(key)) {
      seen.add(key);
      uniqueEPNames.push({
        category_name: item.category_name,
        category_id: item.category,
      });
    }
  });

  return uniqueEPNames;
}
function groupByCategoryId(data) {
  return data.reduce((acc, item) => {
    const categoryIds = item.category;
    if (!acc[categoryIds]) {
      acc[categoryIds] = [];
    }
    acc[categoryIds].push(item);
    return acc;
  }, {});
}

const EsteemedPartners = ({
  esteemedPartners,
  ourESteemedPartner,
  homeHeading,
}) => {
  const uniqueEPNames = getUniqueTCNamesWithCategory(esteemedPartners);
  const groupedPartners = Object.entries(groupByCategoryId(esteemedPartners));
  const [currentTab, setCurrentTab] = useState(uniqueEPNames[0].category_id);

  useEffect(() => {
    // Hide all tab content by default
    document.querySelectorAll(".tabs-env-mn > div").forEach((tab) => {
      tab.style.display = "none";
    });

    // Show the content of the active tab
    const activeTabContent = document.querySelector(`#tab-nav-${currentTab}`);
    if (activeTabContent) {
      activeTabContent.style.display = "block";
    }
  }, [currentTab]);
  const handleTabClick = (tabId) => {
    setCurrentTab(tabId);
  };

  const partnerSlider = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    cssEase: "ease-in",
    fade: false,
    draggable: true,
    autoplaySpeed: 2000,
    speed: 600,
    pauseOnDotsHover: false,
    responsive: [
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  // Separate parent and child items
  return (
    <>
      <section className="bg-white esteemed-partner-tp">
        <div className="container_custom">
          <div className="row">
            <div className="col-md-12">
              <div className="headingFont">
                <h2>
                  {homeHeading[4].value}
                </h2>
                <p className="expand-our-reach">
                  {homeHeading[5].value}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="tabs-env-main">
              <ul className="tabs-nav-mn-tp">
                {uniqueEPNames &&
                  uniqueEPNames.map((tabItem, tabIndex) => (
                    <li
                      key={tabItem.category_id}
                      className={`tab-env-navi-${
                        currentTab === tabItem.category_id ? "active" : ""
                      }`}
                      onClick={() => handleTabClick(tabItem.category_id)}
                    >
                      <Link to="#tab-nav-hosp">{tabItem.category_name}</Link>
                    </li>
                  ))}
              </ul>
              <div className="tabs-env-mn">
                {groupedPartners &&
                  groupedPartners.map(([categoryIds, esteemedPartners]) => (
                    <div
                    key={categoryIds}
                      id={`tab-nav-${categoryIds}`}
                      className={`tab-env-navi-${categoryIds}${
                        currentTab.toString() === categoryIds.toString()
                          ? "active-tab"
                          : ""
                      }`}
                    >
                      <div
                        key={categoryIds}
                        // esteemedPartners={esteemedPartners}
                        className="esteemed-partners-lst"
                      >
                        <Slider {...partnerSlider}>
                          {esteemedPartners &&
                            esteemedPartners.map((finalItem, finalIndex) => (
                              <div key={finalIndex}>
                                <div className="our-est-prtnr">
                                  <Link to="">
                                    <img
                                      alt={finalItem.alt_text}
                                      src={`${fileURL}${finalItem.image}`}
                                    />
                                  </Link>
                                </div>
                              </div>
                            ))}
                        </Slider>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EsteemedPartners;
