export const HOME_URL = `/`;
export const GET_BACK_TO_US = `/get-back-to-us`;
export const POST_AMBULANCE_API = `post-ambulance`;
export const POST_CONTACT_API= `post-contact`;
export function replaceIdInUrl(url, id) {
    return url.replace(':id', id);
  }
  
export const HOME_THANK_YOU_URL = '/thank-you';

export function GET_MENU_CHILD_REL(menu_array) {
    // Assuming you have access to the WordPress menu items through some API or database query
    // For demonstration purposes, let's assume we have the menu items as an array of objects
    let menu = [];
 
    function populate_children(menu_array, menu_item) {
      // console.log(menu_array)
      let children = [];
      if (menu_array.length > 0) {
        menu_array.forEach((m, index) => {
          if (m.parent!=null && m.parent.toString() === menu_item.id.toString()) {
            children.push({
              id: m.id,
              name: m.name,
              // url: m.target!=='_blank'?removeDomainFromUrl(m.url):m.url,
              parent: m.parent,
              order_no: m.order_no,
              slug: m.slug,
              children: populate_children(menu_array.filter((_, i) => i !== index), m)
            });
          }
        });
      }
      return children;
    }
    menu_array.forEach((m) => {
      // alert(m.parent)
      if (m.parent===null) {
        menu.push({
          id: m.id,
          name: m.name,
          // url: m.target!=='_blank'?removeDomainFromUrl(m.url):m.url,
          // pageId: m.object_id,
          // target: m.target,
          parent: m.parent,
          order_no: m.order_no,
          slug: m.slug,
          children: populate_children(menu_array.filter((item) => item.id !== m.id), m)
        });
      }
    });
 console.log("mennu",menu);
    return menu;
  }