import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { fileURL } from "../../services/urls";

const Header = ({ menu, quickTop, address }) => {
  const mainLogo = menu.filter((item) => item.name === "Home");
  const bookAnAmbulance = menu.filter(
    (item) => item.slug === "book-an-ambulance"
  );

  const restMenu = menu.filter(
    (item) => item.name !== "Home" && item.slug !== "book-an-ambulance"
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsSticky(scrollTop >= 30);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.classList.toggle("hidden");
  };

  return (
    <>
      {/* header top starts */}
      <section className="header-top-cnt">
        <div className="container_custom">
          <div className="row">
            <div className="col-md-12">
              <div className="header-top-right">
                <ul>
                  {quickTop && (
                    <>
                      {quickTop.map((item, index) => (
                        <li key={index}>
                          <Link
                            to={item.slug}
                            target={item.open_in_new_tab ? "_blank" : ""}
                            rel={
                              item.open_in_new_tab ? "noopener noreferrer" : ""
                            }
                          >
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* header top ends */}

      {/* <!-- header section start --> */}
      <header className={`header-main sticky_top ${isSticky ? "fixed" : ""}`}>
        <div className="container_custom">
          <div className="row">
            <div className="col-md-3 col-3">
              {mainLogo[0] && mainLogo[0].slug && (
                <Link
                  to={mainLogo[0].slug}
                  target={mainLogo[0].open_in_new_tab ? "_blank" : ""}
                  rel={mainLogo[0].open_in_new_tab ? "noopener noreferrer" : ""}
                >
                  <img
                    src={`${fileURL}/${mainLogo[0].doc_link}`}
                    className="logo_main"
                    alt=""
                  />
                </Link>
              )}
            </div>
            <div className="col-md-3 col-8 mobileSect_Show">
					<div className="resp-book-ambu">
						<Link   to={'tel:+' + address[1].description}>
            {bookAnAmbulance[0].name}
						</Link>
					</div>
				</div>
            <div className="col-md-9 col-1">
              <div className="top_hdr">
                <div className="toprght">
                  <div className="srchlnknw">
                    <Link to="" className="srchclk"></Link>
                  </div>
                </div>
                <div className="mainmenu">
                  <div className="menu_bx">
                    <div className="header__humburgermenu">
                      <div className="header__humburgerblock">
                        <div
                          id="header__humburgericon"
                          className={isMenuOpen ? "open" : ""}
                          onClick={toggleMenu}
                        >
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className={`navinr ${isMenuOpen ? "open" : ""}`}>
                    {restMenu && (
                      <>
                        {restMenu.map((item, index) => (
                          <li key={index}>
                            <Link className="active" to={item.slug}>
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </>
                    )}
                    {/* <li>
                      <Link className="active" to="/">
                        ABOUT US
                      </Link>
                    </li>
                    <li>
                      <Link to="javascript:void(0)">SOLUTIONS</Link>
                    </li>
                    <li>
                      <Link to="javascript:void(0)">PRODUCTS</Link>
                    </li> */}
                    {bookAnAmbulance && (
                      <li className="deskSect_Show">
                        <Link
                          className="book-ambulance"
                          to={'tel:+' + address[1].description}
                        >
                          {bookAnAmbulance[0].name}
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- end header section --> */}

    {/* call and number section start  */}
      <div className="floating__rght__bx">
        <ul className="fltng__lst result">
          <li>
            <span className="icnbx social"></span>
            <ul className="scllnkbx">
              <li>
                <Link to="" target="_blank"></Link>
              </li>
              <li>
                <Link to="" target="_blank"></Link>
              </li>
              <li>
                <Link to="" target="_blank"></Link>
              </li>
            </ul>
          </li>
          <li>
          <Link to={'https://wa.me/' + address[1].description} target="_blank">
            <span className="icnbx frm_clk"></span>
            </Link>
          </li>
          <li>
            <Link to={'tel:+' + address[1].description}>            
              <span className="icnbx lnk_clk"></span>
            </Link>
          </li>
        </ul>
      </div>
      {/* end call and number section  */}
    </>
  );
};

export default Header;

