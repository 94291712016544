import { Link } from "react-router-dom";
function ThankYou() {
  return (
    <>
      <section>
        <h1>Thank You</h1>
        <p>you will receive call in 5 seconds</p>
        <p>Please call us at <Link to='tel:1800121911911'>1800 121 911 911</Link>, if you haven’t received a call yet</p>
      </section>
    </>
  );
}

export default ThankYou;
