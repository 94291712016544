import React, { useEffect } from "react";
import { Link } from "react-router-dom";




import { fileURL } from "../../services/urls";
import $ from "jquery"; // Import jQuery  
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import proArrowLeft from '../../assets/images/home/pro-arrw-lft.webp';





const Products = ({products, homeHeading}) => {
 

      const productSlidder = {
      // rows: 2,
        dots: false,
        infinite: true,
        slidesToShow: 4,	
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        cssEase: 'ease-in',
        fade:false,
        draggable: true,
        autoplaySpeed: 3000,
        speed: 600,			
        pauseOnDotsHover: false,
    
    
      responsive: [
    {
    breakpoint: 1550,
    settings: {
     slidesToShow: 3,
    }
     },
        {
    breakpoint: 800,
    settings: {
     slidesToShow: 1,
    }
     },
        {
    breakpoint: 580,
    settings: {
     slidesToShow: 1,
    }
     }
     ]
    }
    let isMobile = window.innerWidth < 768;

    const updateIsMobile = () => {
      isMobile = window.innerWidth < 768; // Update isMobile when window size changes
      productSlidder.dots = isMobile; // Toggle dots based on isMobile value
    };
    window.addEventListener('resize', updateIsMobile);
    // Call updateIsMobile initially to set the initial state
    updateIsMobile();

  if (!products || !Array.isArray(products)) {
    return <div>Error: No products to display.</div>;
  }
  // Separate parent and child items

  const chunkArray = (products, chunkSize) => {
    const chunks = [];

    for (let i = 0; i < products.length; i += chunkSize) {
      chunks.push(products.slice(i, i + chunkSize));
    }
    return chunks;
  };
  const productsChunks = chunkArray(products, 2);
  // Separate parent and child items
 

  return (
<>
{}
		<section className="bg-white">
			<div className="container_custom">
				<div className="row">
					<div className="col-md-12">
						<div className="headingFont">
							<h2>
                {homeHeading[7].value}
							</h2>
              <p className="product-sub-heading">
                  {homeHeading[8].value}
                </p>
						</div>
					</div>
				</div>
				<div className="row prodct-mn-tp">
					<div className="product_slider" data-aos="fade-left" data-aos-easing="ease" data-aos-delay="300">
          <Slider {...productSlidder}>
			{productsChunks && productsChunks.map((item,index) => (
				<div key={index}>
					{item && item.map((item2,index2) => (
					<div key={index2}  className="prodct-cnt-bot">
						<div className="prodct-cnt-bot-ent-event">
							<h2>
								{item2.article_name}
							</h2>
							<p>
								{item2.short_description}
							</p>
						</div>
						<div className="pro-arrw-left">
							<img alt="Arrow Left" className="img-fluid" src={proArrowLeft}/>
						</div>
						<div className="circle_img_gry">
							<img alt={item2.alt_text} className="img-fluid" src={`${fileURL}${item2.image}`}/>
						</div>
					</div>
				))}
			</div>
			))}
            </Slider>
					</div>
				</div>
			</div>
		</section>
		{}
</>
  );
};

export default Products;
