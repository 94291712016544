import React, { useEffect, useRef, useState, Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import useUpdateMetaTags from "../components/common/updateMetaTags";
import GetBackToUsForm from "../components/forms/GetBackToUs";
import Loader from "../components/common/Loader";
import Banner from "../components/home/banner";
import AmbulanceService from "../components/home/AmbulanceService";
import EnterpriseSolution from "../components/home/EnterpriseSolution";
import "../assets/css/home.css";
import TailoredSolutions from "../components/home/TailoredSolutions";
import EsteemedPartners from "../components/home/EsteemedPartners";
import SpeakWithUs from "../components/home/SpeakWithUs";
import home from "../store/slices/home/home";
import ZeroInterestEmi from "../components/home/ZeroInterestEmi";
import Testimonials from "../components/home/Testimonials";
import Products from "../components/home/Products";
import AboutUs from "../components/home/AboutUs";
import Blogs from "../components/home/Blogs";
import MbBlogs from "../components/home/MbBlog";
import SearchBar from "../components/home/SearchBar";
// Import remaining components lazily
// const Solutions = lazy(() => import("../components/home/Solution"));

function Home() {
  const [isMobile, setIsMobile] = useState(false);

  const { homeData, loading } = useSelector((state) => state.home);
  const ambulanceServiceRef = useRef(null);
  // Update meta tags only if homeData is available
  useUpdateMetaTags(homeData);

  const [isScrolledToAmbulanceService, setIsScrolledToAmbulanceService] =
    useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const ambulanceServiceEl = ambulanceServiceRef.current; // Use the ref to get the element
      if (ambulanceServiceEl) {
        const isScrolled = window.scrollY + 300 > ambulanceServiceEl.offsetTop;
        setIsScrolledToAmbulanceService(isScrolled);
      }
    };

    // Add the event listener after the initial render
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures this effect runs once after initial render

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //uncomment this to have loader on refresh
  // if (loading) {
  //   return <Loader />;
  // }

  return (
    <>
      {isMobile ? (
        // <h1>This is a mobile device</h1>
        <>
            {homeData && homeData.banner && <Banner banner={homeData.banner} />}
            {homeData && homeData.banner && <SearchBar />}
            
            {homeData && homeData.zeroInterestEmi && (
              <div id="ambulance-service"  ref={ambulanceServiceRef}>
            <ZeroInterestEmi zeroInterestEmi={homeData.zeroInterestEmi} />
            </div>
          )}
            {isScrolledToAmbulanceService && ( <>
          {homeData && homeData.solutions && (
            <EnterpriseSolution solutionList={homeData.solutions} />
          )}
           {homeData && homeData.homeAboutUs && (
            <AboutUs homeAboutUs={homeData.homeAboutUs} />
          )}
           {homeData && homeData.tailoredSolutions && homeData.homeHeading && (
            <TailoredSolutions
              tailoredSolutions={homeData.tailoredSolutions}
              homeHeading={homeData.homeHeading}
            />
          )}
           {homeData && homeData.products && homeData.homeHeading &&(
            <Products products={homeData.products}  homeHeading={homeData.homeHeading} />
          )}
          {homeData &&
            homeData.ourEsteemedPartner &&
            homeData.ourESteemedPartnerCategory &&
            homeData.homeHeading && (
              <EsteemedPartners
                esteemedPartners={homeData.ourEsteemedPartner}
                ourESteemedPartner={homeData.ourESteemedPartnerCategory}
                homeHeading={homeData.homeHeading}
              />
            )}
             {homeData &&
            homeData.testimonialSection &&
            homeData.testimonialCategory &&
            homeData.homeHeading && (
              <Testimonials
                testimonials={homeData.testimonialSection}
                testimonialCategory={homeData.testimonialCategory}
                homeHeading={homeData.homeHeading}
              />
            )}
            {
              homeData && homeData.blogs && (
                <MbBlogs mbBlogs={homeData.blogs}/>
              )}
             {/* {homeData && homeData.blogs && <Blogs blogs={homeData.blogs} />} */}
             {homeData &&
            homeData.homeHeading &&
            homeData.footerAddress &&
            homeData.speakWithUs && (
              <SpeakWithUs
                homeHeading={homeData.homeHeading}
                address={homeData.footerAddress}
                socialMediaSWU={homeData.speakWithUs}
              />
            )} </> )}
        </>
      ) : (
        // <h1>This is a desktop device</h1>
        <>
        {homeData && homeData.solutions && (
            <EnterpriseSolution solutionList={homeData.solutions} />
          )}
          
          {homeData && homeData.banner && <SearchBar />}

          {homeData && homeData.banner && <Banner banner={homeData.banner} />}
          {homeData && homeData.ambulanceServices && homeData.homeHeading && (
                  <div id="ambulance-service"  ref={ambulanceServiceRef}>
            <AmbulanceService
              ambulanceServices={homeData.ambulanceServices}
              homeHeading={homeData.homeHeading}
            /></div>
          )}
           {isScrolledToAmbulanceService && ( <>
          {homeData && homeData.homeAboutUs && (
            <AboutUs homeAboutUs={homeData.homeAboutUs} />
          )}
          {homeData && homeData.zeroInterestEmi && (
            <ZeroInterestEmi zeroInterestEmi={homeData.zeroInterestEmi} />
          )}
          {homeData && homeData.tailoredSolutions && homeData.homeHeading && (
            <TailoredSolutions
              tailoredSolutions={homeData.tailoredSolutions}
              homeHeading={homeData.homeHeading}
            />
          )}
          {homeData && homeData.products &&  homeData.homeHeading &&(
            <Products products={homeData.products}  homeHeading={homeData.homeHeading} />
          )}
          {homeData &&
            homeData.ourEsteemedPartner &&
            homeData.ourESteemedPartnerCategory &&
            homeData.homeHeading && (
              <EsteemedPartners
                esteemedPartners={homeData.ourEsteemedPartner}
                ourESteemedPartner={homeData.ourESteemedPartnerCategory}
                homeHeading={homeData.homeHeading}
              />
            )}
          {homeData &&
            homeData.testimonialSection &&
            homeData.testimonialCategory &&
            homeData.homeHeading && (
              <Testimonials
                testimonials={homeData.testimonialSection}
                testimonialCategory={homeData.testimonialCategory}
                homeHeading={homeData.homeHeading}
              />
            )}

          {homeData && homeData.blogs && <Blogs blogs={homeData.blogs} />}

          {homeData &&
            homeData.homeHeading &&
            homeData.footerAddress &&
            homeData.speakWithUs && (
              <SpeakWithUs
                homeHeading={homeData.homeHeading}
                address={homeData.footerAddress}
                socialMediaSWU={homeData.speakWithUs}
              />
            )} </> )} 
        </>
      )}

      {/* {homeData  && <Banner banner={homeData.banner} />}
      <div id="ambulance-service"  ref={ambulanceServiceRef}>
        {homeData && homeData.ambulanceServices && <AmbulanceService ambulanceServices={homeData.ambulanceServices} />}
      </div>

      {isScrolledToAmbulanceService && (
        <>
          {homeData && <Suspense fallback={<Loader />}><AboutUs homeAboutUs={homeData.homeAboutUs} /></Suspense>}
          {homeData &&  <Suspense fallback={<Loader />}><Solutions solutions={homeData.solutions} /></Suspense>}
          {homeData && <Suspense fallback={<Loader />}><Products products={homeData.products} /></Suspense>}
          {homeData && <Suspense fallback={<Loader />}><Testimonials testimonials={homeData.testimonial} /></Suspense>}
          {homeData && <Suspense fallback={<Loader />}><Blogs blogs={homeData.blogs} /></Suspense>}
          {homeData &&  <GetBackToUsForm />}
        </>
      )} */}
    </>
  );
}

export default Home;
