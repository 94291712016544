import React from "react";
// import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { GET_MENU_CHILD_REL } from "../../hooks/url";
import { fileURL } from "../../services/urls";

const Footer = ({ address, footer, socialMedia, footerLogo, footerBottom ,FooterBottomMenu}) => {
  const footerMenu = GET_MENU_CHILD_REL(footer);
   

  return (
    <>
      <footer className="footer-mn">
        <div className="container_custom">
          <div className="row">
            <div className="col-md-2 d-flex justify-content-center align-items-center">
              <Link to={footerLogo[0].description}>
                <img
                  className="img-fluid"
                  src={`${fileURL}${footerLogo[0].value}`}
				  alt={footerLogo[0].description}
                />
              </Link>
            </div>
            <div className="col-md-3">
              <div className="address-cnt address-cnt-pdd">
                <h2>{address[0].name}</h2>
                <p>{address[0].description}</p>
                <ul className="call-email">
                  <li>
					<Link to={'tel:+' + address[1].description}>
                      {`${address[1].name}${address[1].description}`}
                    </Link>
                  </li>
                  <li>
                    <Link to={'mailto:' + address[2].description}>
                      {`${address[2].name}${address[2].description}`}
                    </Link>
                  </li>
                </ul>
                <ul className="social-icon-ft">
                  {socialMedia &&
                    socialMedia.map((socialMediaItem, socialMediaIndex) => (
                      <li key={socialMediaIndex}>
                        <Link to={socialMediaItem.description}>
                          <img src={`${fileURL}${socialMediaItem.value}`}  alt={socialMediaItem.description} />
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            {footerMenu &&
              footerMenu.map((item, index) => (
                <div key={index} className="col-md-2">
                  <div className="address-cnt">
                    {item && <h2>{item.name}</h2>}
                    <ul className="call-email">
                      {item.children &&
                        item.children.map((item2, index2) => (
                          <li key={index2}>
                            <Link to={item2.slug}>{item2.name}</Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              ))}
          </div>
          <div className="row">
					<div className="col-md-12 d-flex justify-content-center text-center">
						<div className="copy-right-txt">
							<p>
                {`${footerBottom[0].value} ©${new Date().getFullYear()} ${footerBottom[1].value} `}
							</p>	
							<p>
                {footerBottom[2].value}
                {FooterBottomMenu && FooterBottomMenu.map((footerItem,footerIndex) => (

                
								<Link key={footerIndex} to={footerItem.slug}>
                {` ${footerItem.name}`}
								</Link>
                ))}
							</p>								
						</div>
					</div>
				</div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
