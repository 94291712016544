import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { fileURL } from "../../services/urls";
import arrow_right from "../../assets/images/home/arrw-rght.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/css/home.css";
import api from "../../services/api";
import { useMutation } from "react-query";
import { POST_AMBULANCE_API } from "../../hooks/url";
import { useNavigate } from "react-router-dom";
import { HOME_THANK_YOU_URL } from "../../hooks/url";
import parse from "html-react-parser";
const Banner = ({ banner }) => {
  // Separate parent and child items
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [csrfToken, setCsrfToken] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    cssEase: "ease-in",
    fade: false,
    draggable: true,
    autoplaySpeed: 3000,
    speed: 600,
    pauseOnDotsHover: false,
  };

  const [formData, setFormData] = useState({
    name: "",
    phoneNo: "",
    errors: {
      name: "",
      phoneNo: "",
    },
  });

  // Function to handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phoneNo' && !/^\d{0,10}$/.test(value)) {
      return; // Exit early if the input contains non-numeric characters or exceeds 10 digits
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Usage:
  const mutation = useMutation(
    ({ newLetter, token }) => {
      const form = new FormData();
      // alert(token)

      Object.entries(newLetter).forEach(([key, value]) => {
        form.append(key, value);
      });
      return api.post(POST_AMBULANCE_API, form, {
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: (data) => {
        if (data.data.success) {
          setIsSuccess(true);
          setMessage(data.data.msg);
          setFormData({
            name: "",
            phoneNo: "",
            errors: {
              name: "",
              phoneNo: "",
            },
          });
          navigate(HOME_THANK_YOU_URL);
        } else {
          setIsSuccess(false);
          if (typeof data.data.msg === "object") {
            setMessage(data.data.msg[0]);
          } else {
            setMessage(data.data.msg);
          }
        }
      },
      onError: (error) => {
        setMessage("Something went wrong");
      },
    }
  );
  const validateForm = () => {
    let errors = {};
    let isValid = true;
    // Validate name
    if (!formData.name) {
      errors.name = <span className="error-message">Name is required</span>;
      isValid = false;
    } else if (formData.name.length > 100) {
      errors.name = (
        <span className="error-message">
          Name should not exceed 100 characters
        </span>
      );
      isValid = false;
    }

    if (!formData.phoneNo) {
      errors.phoneNo = (
        <span className="error-message">Phone Number is required</span>
      );
      isValid = false;
    } else if (!/^[6-9]\d{9}$/.test(formData.phoneNo)) {
      errors.phoneNo = (
        <span className="error-message">Phone Number should be valid and of 10 digits</span>
      );
      isValid = false;
    }  
    // Update errors in state
    setFormData({ ...formData, errors });

    return isValid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      // Fetch CSRF token when the submit button is hit
      const fetchCsrfToken = async () => {
        try {
          const response = await api.get("/csrf-token"); // Assuming you have an endpoint to fetch the CSRF token
          console.log("response",response);
          setCsrfToken(response.data.csrfToken);
          console.log("response.data.csrfToken",response.data.csrfToken);
          return response.data.csrfToken; // Return the token for use outside this function
        } catch (error) {
          console.error("Error fetching CSRF token:", error);
          return null; // Return null if there's an error
        }
      };

      const token = await fetchCsrfToken(); // Wait for the token to be fetched

      if (token) {
        // Now that we have the CSRF token, we can make the request

        await mutation.mutate({ newLetter: formData, token });
      }

      // Reset the form after successful validation
      setFormData({
        name: "",
        phoneNo: "",
        errors: {},
      });
      setLoading(false);
    }
  };

  return (
    <>
      <section className="bg-white">
        <div className="container_custom">
          <div className="row">
            <div className="col-md-3">
              <div className="banner-left-cnt">
                <h2>GET A CALL BACK</h2>
                <h3>
                  IN
                  <span>5</span>
                  SECONDS
                </h3>
                {loading ? (
                  <p>Please wait...</p>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <input
                      className="input-name-field form-control"
                      type="text"
                      id="name"
                      placeholder="NAME"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />

                    {formData.errors.name && (
                      <p className="error-msg" style={{ display: "block" }}>
                        {formData.errors.name}
                      </p>
                    )}
                    <input
                      className="input-name-field form-control"
                      type="text"
                      id="contact"
                      name="phoneNo"
                      placeholder="CONTACT NO."
                      value={formData.phoneNo}
                      onChange={handleChange}
                      maxLength={10}
                    />
                    {formData.errors.phoneNo && (
                      <p className="error-msg" style={{ display: "block" }}>
                        {formData.errors.phoneNo}
                      </p>
                    )}
                    <div className="book-clr-btn book-clr-btn-padd text-right justify-content-end">
                      {/* <Link to="#"> */}
                      <button className="button-banner" type="submit">
                        <span className="form-submit-btn">SUBMIT</span>
                        <img
                          className="image-arrow"
                          src={arrow_right}
                          alt="right arrow"
                        />
                      </button>
                      {/* </Link> */}
                    </div>
                  </form>
                )}
              </div>
            </div>
            <div className="col-md-9">
              <div className="banner_slider">
                <Slider {...settings}>
                  {banner &&
                    banner.map((item, index) => (
                      <div key={index}>
                        <div className="bnrbx">
                          <img
                            className="img-fluid desktop-banner-image"
                            src={`${fileURL}${item.desktop_doc_url}`}
                            alt={item.alt_text}
                          />
                          {/* <img className="img-fluid mobile-banner-image"  src={`${fileURL}${item.mobile_doc_url}`} alt={item.alt_text}/> */}
                        </div>
                        <div className="bnr__cntnt">
                          <div className="vrtclcntr_bx">
                            <div className="vrtclcntr_bxinr">
                              <div className="container">
                                <div className="banner_txt banner-right-cnt">
                                  <h2>{parse(item.heading)}</h2>
                                  <p>{parse(item.sub_heading)}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
