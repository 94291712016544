import axios from 'axios'

const baseURL = 'https://redhealth.hashtechorange.com/';
// const baseURL = 'http://localhost:7000/';

const api = axios.create({
    baseURL,
    withCredentials: true 
})

export default api;

// import axios from 'axios';
// import CryptoJS from 'crypto-js';
// import { email,password,secret } from './urls';

// const baseURL = 'http://localhost:7000/';

// // Function to fetch the token
// const fetchToken = async () => {
//   try {
//     const encryptedEmail = CryptoJS.AES.encrypt(email, secret).toString();
//     const encryptedPassword = CryptoJS.AES.encrypt(password, secret).toString();

//     const response = await axios.post(`${baseURL}token`,{email:encryptedEmail,password:encryptedPassword});
//     const newToken = response.data.accessToken; // Adjust this according to your API response
//     return newToken;
//   } catch (error) {
//     console.error('Error fetching token:', error);
//     throw error;
//   }
// };

// // Create an Axios instance with the base URL
// const api = axios.create({
//   baseURL,
// });

// // Add a request interceptor to add the token to the headers
// api.interceptors.request.use(
//   async (config) => {
//     let token = localStorage.getItem('token');
//     if (!token || token === 'undefined') {
//       token = await fetchToken();
//       localStorage.setItem('token', token);
//     }
//     config.headers.Authorization = `Bearer ${token}`;
//     return config;
//   },
//   (error) => {
//     Promise.reject(error);
//   }
// );

// // Add a response interceptor to handle token refresh
// api.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       const token = await fetchToken();
//       localStorage.setItem('token', token);
//       axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
//       return api(originalRequest);
//     }
//     return Promise.reject(error);
//   }
// );

// export default api;