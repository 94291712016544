import React, { useState } from "react";
import { Link } from "react-router-dom";
import arrowRight from "../../assets/images/home/arrw-rght.png";
import { useMutation } from "react-query";
import { fileURL } from "../../services/urls";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";

import { POST_CONTACT_API, HOME_THANK_YOU_URL } from "../../hooks/url";
const SpeakWithUs = ({ homeHeading, address, socialMediaSWU, speakWithUs }) => {
  // Separate parent and child items
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [csrfToken, setCsrfToken] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    phoneNo: "",
    message: "",
    errors: {
      name: "",
      phoneNo: "",
      message: "",
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Check if the input value is numeric and not exceeding 10 characters
    if (name === 'phoneNo' && !/^\d{0,10}$/.test(value)) {
      return; // Exit early if the input contains non-numeric characters or exceeds 10 digits
    }
  
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const mutation = useMutation(
    ({ newLetter, token }) => {
      const form = new FormData();
      // alert(token);

      Object.entries(newLetter).forEach(([key, value]) => {
        form.append(key, value);
      });
      return api.post(POST_CONTACT_API, form, {
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: (data) => {
        if (data.data.success) {
          setIsSuccess(true);
          setMessage(data.data.msg);
          setFormData({
            name: "",
            phoneNo: "",
            message: "",
            errors: {
              name: "",
              phoneNo: "",
              message: "",
            },
          });
          navigate(HOME_THANK_YOU_URL);
        } else {
          setIsSuccess(false);
          if (typeof data.data.msg === "object") {
            setMessage(data.data.msg[0]);
          } else {
            setMessage(data.data.msg);
          }
        }
      },
      onError: (error) => {
        setMessage("Something went wrong");
      },
    }
  );
  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Validate name
    if (!formData.name) {
      errors.name = <span className="error-message">Name is required</span>;
      isValid = false;
    } else if (formData.name.length > 100) {
      errors.name = (
        <span className="error-message">
          Name should not exceed 100 characters
        </span>
      );
      isValid = false;
    }
    // Validate name
    if (!formData.message) {
      errors.message = (
        <span className="error-message">Message is required</span>
      );
      isValid = false;
    }
    // Validate Phone Number details
    if (!formData.phoneNo) {
      errors.phoneNo = (
        <span className="error-message">Phone Number is required</span>
      );
      isValid = false;
    } else if (!/^[6-9]\d{9}$/.test(formData.phoneNo)) {
      errors.phoneNo = (
        <span className="error-message">Phone Number should be valid and of 10 digits</span>
      );
      isValid = false;
    }
  
    // Update errors in state
    setFormData({ ...formData, errors });

    return isValid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      // Fetch CSRF token when the submit button is hit
      const fetchCsrfToken = async () => {
        try {
          const response = await api.get("/csrf-token"); // Assuming you have an endpoint to fetch the CSRF token
          setCsrfToken(response.data.csrfToken);
          return response.data.csrfToken; // Return the token for use outside this function
        } catch (error) {
          console.error("Error fetching CSRF token:", error);
          return null; // Return null if there's an error
        }
      };

      const token = await fetchCsrfToken(); // Wait for the token to be fetched

      if (token) {
        // Now that we have the CSRF token, we can make the request

        await mutation.mutate({ newLetter: formData, token });
      }

      // Reset the form after successful validation
      setFormData({
        name: "",
        organisation: "",
        phoneNo: "",
        errors: {},
      });
      setLoading(false);
    }
  };
  return (
    <>
      <section className="bg-white speak-with-us">
        <div className="container_custom">
          <div className="row">
            <div className="col-md-12 speak-with-us-cont">
              <div className="row">
                <div className="col-md-6" data-aos="fade-right" data-aos-easing="ease" data-aos-delay="200">
                  <div className="speak-with-us-lft-top">
                    <h2>{homeHeading[3].name}</h2>
                    <p>{homeHeading[3].description}</p>
                  </div>
                  <div className="speak-with-us-lft-bot">
                    <ul>
                      <li>
                        <Link to={"tel:+" + address[1].description}>
                          {`${address[1].name}${address[1].description}`}
                        </Link>
                      </li>
                      <li>
                        <Link to={"mailto:" + address[2].description}>
                          {`${address[2].name}${address[2].description}`}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6" data-aos="fade-left" data-aos-easing="ease" data-aos-delay="200">
                  <div className="speak-with-us-rght">
                    {loading ? (
                      <p>Please wait...</p>
                    ) : (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="label-name-text" htmlFor="">
                              NAME
                            </label>
                            <input
                              className="speak-name-field form-control"
                              name="name"
                              type="text"
                              id=""
                              placeholder="NAME"
                              value={formData.name}
                              onChange={handleChange}
                            />
                            {formData.errors.name && (
                              <p
                                className="error-msg"
                                style={{ display: "block" }}
                              >
                                {formData.errors.name}
                              </p>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label className="label-name-text" htmlFor="">
                              CALL
                            </label>
                            <input
                              className="speak-name-field form-control"
                              name="phoneNo"
                              type="text"
                              id=""
                              placeholder="990*******"
                              value={formData.phoneNo}
                              onChange={handleChange}
                              maxLength={10}
                            />
                            {formData.errors.phoneNo && (
                              <p
                                className="error-msg"
                                style={{ display: "block" }}
                              >
                                {formData.errors.phoneNo}
                              </p>
                            )}
                          </div>
                          <div className="col-md-12">
                            <div className=" pb-lg-4">
                              <label className="label-name-text" htmlFor="">
                                MESSAGE
                              </label>
                              <textarea
                                id="textarea"
                                rows="3"
                                name="message"
                                placeholder="Message"
                                className="speak-name-field"
                                value={formData.message}
                                onChange={handleChange}
                              ></textarea>
                              {formData.errors.message && (
                                <p
                                  className="error-msg"
                                  style={{ display: "block" }}
                                >
                                  {formData.errors.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="book-clr-btn book-clr-btn1 paading-top">
                          <button className="button-banner" type="submit">
                            <span className="form-submit-btn">Submit</span>
                            <img
                              className="image-arrow"
                              src={arrowRight}
                              alt="arrow right"
                            />
                          </button>
                          {/* </Link> */}
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="social-icon-footer">
                    <ul>
                      {socialMediaSWU &&
                        socialMediaSWU.map((item, index) => (
                          <li key={index}>
                            <Link to={item.description}>
                              <img
                                src={`${fileURL}${item.value}`}
                                alt={item.description}
                              />
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SpeakWithUs;
