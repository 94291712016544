import { useEffect } from 'react';

const useUpdateMetaTags = (apiResponse) => {
  useEffect(() => {
    // Parse the API response to get the page data
    const pageData = apiResponse?.page?.[0];

    // Function to update the title tag
    const updateTitle = (title) => {
      document.title = title || '';
    };

    // Function to update a meta tag
    const updateMetaTag = (name, content) => {
      // Find the existing meta tag or create a new one
      let metaTag = document.querySelector(`meta[name="${name}"]`);
      if (!metaTag) {
        metaTag = document.createElement('meta');
        document.head.appendChild(metaTag);
      }

      // Set the name and content attributes
      metaTag.setAttribute('name', name);
      metaTag.setAttribute('content', content);
    };

    // Function to directly inject raw HTML into the head
    const injectRawHTML = (html) => {
      // Create a temporary container to parse the HTML string
      const container = document.createElement('div');
      container.innerHTML = html;
      // Append each child node of the container to the head
      while (container.firstChild) {
        document.head.appendChild(container.firstChild);
      }
    };

    // Update the title
    if (pageData?.meta_title) {
      updateTitle(pageData.meta_title);
    }

    // Update the meta description
    if (pageData?.meta_description) {
      updateMetaTag('description', pageData.meta_description);
    }
    if (pageData?.meta_description) {
        updateMetaTag('title', pageData.meta_title);
      }
    // Inject the raw HTML content of meta_others
    if (pageData?.meta_others) {
      injectRawHTML(pageData.meta_others);
    }
  }, [apiResponse]);
};

export default useUpdateMetaTags;