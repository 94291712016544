import React from "react";
import { Link } from "react-router-dom";
import arrowRight from '../../assets/images/home/arrw-rght.png';
import { fileURL } from "../../services/urls";
import parse from 'html-react-parser';
import Slider from "react-slick";


const ZeroInterestEmi = ({ zeroInterestEmi}) => {
  // Separate parent and child items
  const zeroSlidder ={
        dots: false,
        infinite: true,
        slidesToShow: 3,	
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        cssEase: 'ease-in',
        fade:false,
        draggable: true,
        autoplaySpeed: 3000,
        speed: 600,			
        pauseOnDotsHover: false,
        responsive: [
    {
    breakpoint: 1550,
    settings: {
     slidesToShow: 3,
    }
     },
    
    {
    breakpoint: 1200,
    settings: {
     slidesToShow: 2,
    }
     }, 
            {
    breakpoint: 800,
    settings: {
     slidesToShow: 1,
    }
     },
            {
    breakpoint: 580,
    settings: {
     slidesToShow: 1,
    }
     }
     ]
        
  }
  let isMobile = window.innerWidth < 768;

		const updateIsMobile = () => {
		  isMobile = window.innerWidth < 768; // Update isMobile when window size changes
		  zeroSlidder.dots = isMobile; // Toggle dots based on isMobile value
		};
		window.addEventListener('resize', updateIsMobile);
		// Call updateIsMobile initially to set the initial state
		updateIsMobile();

  return (
    <>
    <section className="bg-white zero-interest">
			<div className="container_custom">
				<div className="row our-tail-sol-mn" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="200">
					<div className="zero-interest-Emi">
                    <Slider {...zeroSlidder}>
                        {zeroInterestEmi && zeroInterestEmi.map((item,index)=> (
						<div key={index}>
							<div className="zero-int-sol-bot">
								<div className="zero-int-cont">
									<h2>
                                        {parse(item.short_description)}
									</h2>
								</div>
								<div className="zero-book-clr-btn paading-top">
									<Link to={item.slug}>
										<span>
											BOOK NOW
										</span>
									</Link>
								</div>
								<div className="zero-emi-circle_back_frst">
									<div className="zero-emi-circle_back_scnd">
									
									</div>									
								</div>
								<div className="zero-emi-circle_img">
									<img className="img-fluid" src={`${fileURL}${item.image}`} alt=""/>
								</div>
							</div>
						</div>
                        ))}
                        </Slider>
					</div>
				</div>
			</div>
		</section>
    </>
  );
};

export default ZeroInterestEmi;