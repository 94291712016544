import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import blogArrow from "../../assets/images/home/blg-arrow.webp";

import { fileURL } from "../../services/urls";

const MbBlogs = ({ mbBlogs }) => {
  const mbBlogSlidder = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    cssEase: "ease-in",
    fade: false,
    draggable: true,
    autoplaySpeed: 3000,
    speed: 600,
    pauseOnDotsHover: false,

    responsive: [
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  console.log("mbBlogs", mbBlogs);
  //   if (!blogs || !Array.isArray(blogs)) {
  //     return <div>Error: No blogs to display.</div>;
  //   }
  //   // Separate parent and child items

  //   const chunkArray = (blogs, chunkSize) => {
  //     const chunks = [];

  //     for (let i = 0; i < blogs.length; i += chunkSize) {
  //       chunks.push(blogs.slice(i, i + chunkSize));
  //     }
  //     return chunks;
  //   };
  //   const blogChunks = chunkArray(blogs, 4);
  return (
    <>
      
      <section className="bg-white mobileShow">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="headingFont">
                <h2>
                 BLOGS
                </h2>
              </div>
            </div>
          </div>
          <div className="blogs_slide_mb">
            <Slider {...mbBlogSlidder}>
            {mbBlogs &&
              mbBlogs.map((item, index) => (
                <div key={index}>
                  <div className="row m-0 blog-rght-tp-mn">
                    <div className="col-12 p-0">
                      <img
                        className="img-fluid"
                        src={`${fileURL}${item.image}`}
                        alt={item.alt_text}
                      />
                    </div>
                    <div className="col-12">
                      <div className="blog-lft-mn p-0">
                        <h2 className="pt-0">
                          {/* Cardiac Arrest: Recognizing Signs and Next Steps */}
                          {item.article_name}
                        </h2>
                        <p>
                          {/*     Lorem ipsum dolor sit amet, consecte adipiscing elit. Adipiscing pharetra */}
                          {item.short_description}
                        </p>
                        
                        <div className="blg-arrow-bt mt-0">
                          
                          <Link
                            className="d-flex justify-content-end"
                            to={item.slug}
                          >
                            <img src={blogArrow} alt="" />
                          </Link>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
             
          </div>
          
        </div>
        
      </section>
    </>
  );
};

export default MbBlogs;
