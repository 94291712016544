import React from "react";
import { Link } from "react-router-dom";
import arrowRight from '../../assets/images/home/arrw-rght.png';
import { fileURL } from "../../services/urls";
import Slider from "react-slick";

const TailoredSolutions = ({ tailoredSolutions, homeHeading}) => {

	// $(".tailored-solutn").slick({
		const slider ={
			dots: false,
			infinite: true,
			slidesToShow: 3,	
			slidesToScroll: 1,
			arrows: false,
			autoplay: false,
			cssEase: 'ease-in',
			fade:false,
			draggable: true,
			autoplaySpeed: 3000,
			speed: 600,			
			pauseOnDotsHover: false,
		
		
			responsive: [
		{
		breakpoint: 1550,
		settings: {
		 slidesToShow: 3,
		}
		 },
		
		{
		breakpoint: 1200,
		settings: {
		 slidesToShow: 2,
		}
		 }, 
				{
		breakpoint: 800,
		settings: {
		 slidesToShow: 1,
		}
		 },
				{
		breakpoint: 580,
		settings: {
		 slidesToShow: 1,
		}
		 }
		 ]
		}

		let isMobile = window.innerWidth < 768;

		const updateIsMobile = () => {
		  isMobile = window.innerWidth < 768; // Update isMobile when window size changes
		  slider.dots = isMobile; // Toggle dots based on isMobile value
		};
		window.addEventListener('resize', updateIsMobile);
		// Call updateIsMobile initially to set the initial state
		updateIsMobile();

  // Separate parent and child items
  return (
    <>
     <section className="bg-white">
			<div className="container_custom">
				<div className="row">
					<div className="col-md-12">
						<div className="headingFont">
							<h2>
								{homeHeading[1].value}
							</h2>
							<p className="tailored-solution-sub-heading">
				{homeHeading[9].value}
                </p>
						</div>
					</div>
				</div>
				<div className="row our-tail-sol-mn" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="200">
				<div className="tailored-solutn">

					<Slider {...slider}>
					{tailoredSolutions && tailoredSolutions.map((item,index) => (
							<div key={index} className="col-md-4">
							<div className="our-tail-sol-bot">
								<div className="hosp-ent-event">
									<h2>
										{item.article_name}
									</h2>
									<p>
									{/* {item.content} */}
										{item.except_description}
									</p>
								</div>
								<div className="book-clr-btn  paading-top">
									<Link to={item.slug}>
										<span>
											READ MORE											
										</span>
										<img src={arrowRight} alt="arrow right"/>
									</Link>
								</div>
									<div className="circle_back_frst">
										<div className="circle_back_scnd">
										
									</div>									
									</div>
								<div className="circle_img">
									<img src={`${fileURL}${item.image}`} alt={item.alt_text} />

								</div>
							</div>
						</div>
					))}
					</Slider>
				</div>
				</div>
			</div>
		</section>
    </>
  );
};

export default TailoredSolutions;